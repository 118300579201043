import React from 'react';
import { List, Datagrid, TextField, DateField,ReferenceField, NumberField, BooleanField, EditButton,DeleteButton, ImageField } from 'react-admin';

export const CountryList = props => (
    <List {...props} title="Country">
        <Datagrid rowClick="show">
            <EditButton />
            <TextField source="name" label="Name" />
            <TextField source="code" label="Code" />
            <BooleanField source="isActive" label="Is Active" />
        </Datagrid>
    </List>
);