import React, { useState, useEffect } from 'react';
import { Edit, Button, Create, SimpleForm, TextInput, ReferenceInput, SelectInput, required ,Toolbar, SaveButton} from 'react-admin';
import { useForm } from 'react-final-form';
import * as apiClient from "../../common/apiClient";

const ChangePasswordCreateToolbar = props => {
    const form = useForm();
    const saveData = (formdata) => {
        
            let user: any = localStorage.getItem("userId");
            if (user) {
            user = parseInt(user);
            }
        //console.log(formdata);
        let data = {
            existing_password: formdata.oldpassword,
            new_password: formdata.newpassword,
            confirmpassword: formdata.confirmpassword,
            userId:user

        };
        props.setMessage("");
        apiClient.ChangePassword(data).then((res) => {
            if (res[0].result) {
                props.setMessage("Password changed successfully");
                form.change("oldpassword", "");
                form.change("newpassword", "");
                form.change("confirmpassword", "");
            } else {
                props.setMessage(res[0].message);
            }
        });
    }
    
    return (
        <Toolbar {...props} >
            <SaveButton  {...props} label="Change password" onSave={saveData}/>
        </Toolbar>
    )
};

export const ChangePassword = props => {
    const [message, setMessage] = useState("");  
    return (
        <React.Fragment>
            <Create title="Change Password" {...props} basePath="changepassword" resource="changepassword">
                <SimpleForm
                    toolbar={<ChangePasswordCreateToolbar setMessage={setMessage}/>}
                    variant="outlined">
                    <TextInput source="oldpassword" label="Existing Password" type="password" validate={[required()]} />
                    <TextInput source="newpassword" label="New Password" type="password" validate={[required()]} />
                    <TextInput source="confirmpassword" label="Confirm Password" type="password" validate={[required()]} />
                </SimpleForm>
            </Create>
            {message && <div className="change-password-result">{message}</div>}
        </React.Fragment>
    );


}