import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,DateInput,
  SelectInput,
  required, ImageInput, ImageField,
  BooleanInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';

export const ActivityCreate = (props) => {
  const classes = useStyles({});
  return (
    <Create title="Create Blog Post" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        <TextInput source="name" fullWidth={true} validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
