import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  PasswordInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
  DateInput,
  BooleanInput,
  FormDataConsumer
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const UsersCreate = (props) => {
  const classes = useStyles({});
  return (
    <Create title="User Create" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        <TextInput source="code" validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="userName" formClassName={classes.two_three_input} />
        <PasswordInput source="password" formClassName={classes.last_three_input} />

        <ReferenceInput label="Role" source="roleTypeId" reference="role-types" formClassName={classes.one_three_input}>
          <SelectInput optionText="roleName" validate={[required()]} />
        </ReferenceInput>
        {/* <ReferenceInput label="Type" formClassName={classes.two_three_input}
          perPage={5000} source="typeId"
          filter={{ type: 'USR' }}
          reference="lookups" >
          <SelectInput optionText="name" variant="outlined" formClassName={classes.one_three_input} />
        </ReferenceInput> */}


        <BooleanInput source="isActive" validate={[required()]} label="Active" formClassName={classes.two_three_input} />



      </SimpleForm>
    </Create>
  );
};
