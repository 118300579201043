import React, { useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput,ReferenceInput,SelectInput,
    DateInput, BooleanInput, NumberInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    }
}));

export const CountryEdit = props => {
    const classes = useStyles({});
    return (
        <Edit {...props} title="Country">
            <SimpleForm redirect="list" variant="outlined">
                <TextInput validate={required()} source="name" label="Name" fullWidth={true} />
                <TextInput validate={required()} source="code" label="Code" fullWidth={true} />
                <BooleanInput initialValue={true} source="isActive" />
            </SimpleForm>
        </Edit>
    );
}