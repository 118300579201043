import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput, DateInput,
  SelectInput,
  required, ImageInput, ImageField,
  BooleanInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';

export const ActivityCreate = (props) => {
  const classes = useStyles({});
  return (
    <Create title="Create Job Post" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        <TextInput source="jobCode" fullWidth={true} validate={[required()]} />
        <DateInput source="postDate" fullWidth={true} validate={[required()]} />
        <TextInput source="designation" fullWidth={true} validate={[required()]} />
        <TextInput source="location" fullWidth={true} validate={[required()]} />
        <TextInput source="salaryFrom" label="Salary / Hourly Rate($) From" fullWidth={true} validate={[required()]} />
        <TextInput source="salaryTo" label="Salary / Hourly Rate($) To" fullWidth={true} validate={[required()]} />
        <RichTextInput source="description" label="Description" fullWidth={true} />
        <ReferenceInput perPage={2000} label="Qualification" source="qualificationId" reference="qualifications" fullWidth={true} validate={[required()]} sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="startDate" fullWidth={true} validate={[required()]} />
        <DateInput source="endDate" fullWidth={true} validate={[required()]} />
        <TextInput source="status" fullWidth={true} validate={[required()]} />
      </SimpleForm>
    </Create>
  );
};
