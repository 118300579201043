import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,DateInput,
  required, ImageInput, ImageField,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';
import { useStyles } from "../../formStyles";
import CustomImageDisplay from "../../common/custom-image-display";

export const ActivityEdit = (props) => {

  const classes = useStyles({});

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? false : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <Edit {...propsObj} title="Event Edit">
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <TextInput source="category" fullWidth={true} validate={[required()]} />
        <TextInput source="shortText" multiline={true} fullWidth={true} validate={[required()]} />
        <RichTextInput  
        toolbar={[
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'link', 'video'],
          ['clean']
        ]}
        source="postContent" label="Post Contents" fullWidth={true} />

        <ImageInput source="imageUrl" fullWidth={true}
          label="Post Image" accept="image/*" className="image" >
          <ImageField source="imageUrl" title="Image" />
        </ImageInput>
        <CustomImageDisplay field="imageUrl" />
      </SimpleForm>
    </Edit>
  );
};
