import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput, DateInput,
  required, ImageInput, ImageField,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';
import { useStyles } from "../../formStyles";
import CustomImageDisplay from "../../common/custom-image-display";

export const ActivityEdit = (props) => {

  const classes = useStyles({});

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? false : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <Edit {...propsObj} title="Job Edit">
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        <TextInput source="jobCode" fullWidth={true} validate={[required()]} />
        <DateInput source="postDate" fullWidth={true} validate={[required()]} />
        <TextInput source="designation" fullWidth={true} validate={[required()]} />
        <TextInput source="location" fullWidth={true} validate={[required()]} />
        <TextInput source="salaryFrom" label="Salary / Hourly Rate($) From" fullWidth={true} validate={[required()]} />
        <TextInput source="salaryTo" label="Salary / Hourly Rate($) To" fullWidth={true} validate={[required()]} />
        <RichTextInput source="description" label="Description" fullWidth={true} />
        <ReferenceInput perPage={2000} label="Qualification" source="qualificationId" reference="qualifications" fullWidth={true} validate={[required()]} sort={{ field: 'name', order: 'ASC' }}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="startDate" fullWidth={true} validate={[required()]} />
        <DateInput source="endDate" fullWidth={true} validate={[required()]} />
        <TextInput source="status" fullWidth={true} validate={[required()]} />
      </SimpleForm>
    </Edit>
  );
};
