import React from "react";
import { DisplayDate } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField, RichTextField
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Post Name"
      source="name~like"
      alwaysOn
    />
  </Filter>
);

const DisplayFileLink = (props) => {
  let { record, source } = props;
  if (source && record[source] && record[source].length > 0)
    return (
      <a href={record[source]}>View File</a>
    )
  else {
    return null
  }
}

export const ActivityList = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? false : false;
      propsObj.hasShow = (p.View == 1) ? false : false;
      propsObj.hasEdit = (p.Edit == 1) ? false : false;
      propsObj.hasDelete = (p.Delete == 1) ? false : false;
      propsObj.hasCreate = (p.Create == 1) ? false : false;
      propsObj.hasExport = (p.Export == 1) ? false : false;
    }
  }

  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} 
    sort={{ field: 'createdDate', order: 'DESC' }}
    title="Job Log List" filters={<Filters />}  >
      <Datagrid rowClick={false}>      
        <TextField source="customerName" label="Name " />
        <TextField source="address" label="Message" /> 
        <TextField source="mobileNo" label="Mobile No" /> 
        <TextField source="email" label="Email" />   
        <DisplayDate  source="applyDate" label="Apply Date" />      
        <ReferenceField source="jobId" reference="jobs" label="Job" link={false}>
          <TextField source="jobCode" />
        </ReferenceField>
        <ReferenceField source="jobId" reference="jobs" label="Job" link={false}>
          <TextField source="designation" />
        </ReferenceField>
        <DisplayFileLink source="resume" label="Resume"/>
        {/* <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDate source="modifydate" label="Updated Date" locales="fr-FR" /> */}
      </Datagrid>
    </List>
  );
};
