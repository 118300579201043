import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,ReferenceField,
    BooleanField, EditButton, DeleteButton, ImageField
} from 'react-admin';

export const CityList = props => (
    <List {...props} title="City">
        <Datagrid rowClick="show">
            <EditButton />
            <TextField source="code" label="City Code" />
            <TextField source="name" label="City Name" />
            <ReferenceField source="districtId" reference="district" label="District"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            {/* <ReferenceField source="stateid" reference="states" label="States"  link={false}>
                <TextField source="statename" />
            </ReferenceField> */}
            <BooleanField source="isActive" label="Is Active" />
        </Datagrid>
    </List>
);