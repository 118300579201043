export const setHeaders = (headers) => {
    if (!headers) headers = new Headers();
    //Authorization: "Bearer " + localStorage.getItem("token"),
    let token = localStorage.getItem('token');
    headers.append("Authorization", "Bearer " + token);
    headers.append("Content-Type", "application/json");

    let HospitalId = "";
    if(localStorage.getItem("HospitalId")){
      HospitalId = localStorage.getItem("HospitalId");
    }
    headers.append("hospitalid",  HospitalId);

    return headers;
}

export const post = (url, model = null, headers = null) => {
    headers = setHeaders(headers);
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(model),
        headers
    }).then(r => {
        if (r.status == 409) {
            return r.json();
        }
        if (!r.ok) {
            throw Error(r.statusText);
        }
        return r.json();
    });
};

export const patch = (url, model = null, headers = null) => {
    headers = setHeaders(headers);
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(model),
        headers
    }).then(r => {
        if (r.status == 409) {
            return r.json();
        }
        if (!r.ok) {
            throw Error(r.statusText);
        }
        return r.json();
    });
};

export const get = (url, headers = null) => {
    headers = setHeaders(headers);
    return fetch(url, {
        method: "GET",
        headers
    }).then(r => {
        if (!r.ok) {
            throw Error(r.statusText);
        }
        
        
        return r.json();
    });
};

export const put = (url, model, headers = null) => {
    headers = setHeaders(headers);
    return fetch(url, {
        method: "PUT",
        body: JSON.stringify(model),
        headers
    }).then(r => {
        if (!r.ok) {
            throw Error(r.statusText);
        }
        return r.json();
    });
};
// delete is reserved word so del
export const del = (url, headers) => {
    headers = setHeaders(headers);
    return fetch(url, {
        method: "DELETE",
        headers
    }).then(r => {
        if (!r.ok) {
            throw Error(r.statusText);
        } else if (r.status == 204) {
            return r.statusText;
        }
        return r.json();
    });
};