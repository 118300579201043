import React from 'react';
const CustomImageDisplay = (props) => {
    return (
        <span>
            <div>Existing Image</div>
            {props.record.imageUrl && <img src={props.record.imageUrl}/>}
        </span>
    )
  };

  export default CustomImageDisplay;