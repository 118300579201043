import React from 'react';
import {
    List, Datagrid, TextField, DateField, NumberField,ReferenceField,
    BooleanField, EditButton, DeleteButton, ImageField
} from 'react-admin';

export const DistrictList = props => (
    <List {...props} title="District">
        <Datagrid rowClick="show">
            <EditButton />
            <TextField source="code" label="District Code" />
            <TextField source="name" label="District Name" />
            <ReferenceField source="stateId" reference="states" label="States"  link={false}>
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isActive" label="Is Active" />
        </Datagrid>
    </List>
);