import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,DateInput,
  SelectInput,
  required, ImageInput, ImageField,
  BooleanInput,
} from "react-admin";
import { useStyles } from "../../formStyles";
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';

export const ActivityCreate = (props) => {
  const classes = useStyles({});
  return (
    <Create title="Create Blog Post" {...props}>
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        <TextInput source="category" fullWidth={true} validate={[required()]} />
        <TextInput source="shortText" multiline={true} fullWidth={true} validate={[required()]} />
        <RichTextInput  toolbar={[
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'link', 'video'],
          ['clean']
        ]}
        source="postContent" label="Post Contents" fullWidth={true} />

        <ImageInput source="imageUrl" fullWidth={true}
          label="Post Image" accept="image/*" className="image" >
          <ImageField source="imageUrl" title="Image" />
        </ImageInput>
      </SimpleForm>
    </Create>
  );
};
