import React from 'react';
import { Edit, SimpleForm, TextInput, DateInput,
     BooleanInput, NumberInput,ReferenceInput,SelectInput } from 'react-admin';

export const StateEdit = props => (
    <Edit {...props} title="State">
        <SimpleForm>
            <TextInput source="id" />
            <ReferenceInput source="countryId" reference="countries">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" />
            <TextInput source="code" />

            <BooleanInput source="isActive" />
        </SimpleForm>
    </Edit>
);