import React, { useState, useEffect, useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import * as apiClient from "../../common/apiClient";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 15,
    },
}));


export const MyCheckbox = ({ value, right, category, name, onChange }) => {
    let [stateChecked, setStateChecked] = useState(false);
    const handleOnChange = (e) => {
        if (stateChecked) {
            setStateChecked(false);
            onChange(0, right, category, name);
        } else {
            onChange(1, right, category, name);
            setStateChecked(true);
        }
    }
    useEffect(() => {
        if (value == 1)
            setStateChecked(true);
        else
            setStateChecked(false);
    }, [value]);
    return (
        <FormControlLabel
            control={<Checkbox checked={stateChecked}
                onChange={handleOnChange}
                name={name} />}
            label={name}
        />
    )
}

export const RoleTypesEditModule = ({ right, category, rightIndex, flagAllrights,selectUnselectItem }) => {
    const [item, setItem] = useState(null);
    const classes = useStyles();

    const selectAllItems = (e) => {
        let lr = { ...right };
        if (e.target.checked) {
            lr.Create = lr.Delete = lr.Export = lr.Edit = lr.View = 1;
        } else {
            lr.Create = lr.Delete = lr.Export = lr.Edit = lr.View = 0;
        }
        setItem(lr);
        flagAllrights(e, lr,category);
    }
    const mineselectUnselectItem = (flag, right, category, name) => {
        let i = { ...item };
        i[name] = flag;
        setItem(i);
        selectUnselectItem(flag, i, category, name);
    }
    useEffect(() => {
        setItem(right);
    }, []);
    return (

        <div className="item-parent">
            <div className="item-header">
                {item && <div className="item-caption">{item.Module}</div>}
                {item && <div className="item-switch"><Switch className="right-toggle"
                    onChange={(e) => { selectAllItems(e); }}
                    name="checkedA"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /></div>}
            </div>

            {item && <div className="right-option">
                <MyCheckbox onChange={mineselectUnselectItem} right={right} category={category} name="View" value={item.View} />
                <MyCheckbox onChange={mineselectUnselectItem} right={right} category={category} name="Create" value={item.Create} />
                <MyCheckbox onChange={mineselectUnselectItem} right={right} category={category} name="Edit" value={item.Edit} />
                <MyCheckbox onChange={mineselectUnselectItem} right={right} category={category} name="Delete" value={item.Delete} />
                <MyCheckbox onChange={mineselectUnselectItem} right={right} category={category} name="Export" value={item.Export} />
            </div>}
        </div>

        // <AccordionItem key={rightIndex} uuid={rightIndex}>
        //     <div className="header-parent">
        //         <AccordionItemHeading className="accord-header">
        //             <AccordionItemButton>
        //                 {item && <div className="item-caption">{item.Module}</div>}
        //             </AccordionItemButton>
        //         </AccordionItemHeading>
        //         {item && <div className="item-switch"><Switch className="right-toggle"
        //             onChange={(e) => { selectAllItems(e); }}
        //             name="checkedA"
        //             inputProps={{ 'aria-label': 'secondary checkbox' }}
        //         /></div>}
        //     </div>
        //     <AccordionItemPanel>
        //         <div className="right-option">
        //             {item && <FormGroup row>
        //                 <MyCheckbox onChange={selectUnselectItem} right={right} category={category} name="Read" value={item.Read} />
        //                 <MyCheckbox onChange={selectUnselectItem} right={right} category={category} name="Create" value={item.Create} />
        //                 <MyCheckbox onChange={selectUnselectItem} right={right} category={category} name="Modify" value={item.Modify} />
        //                 <MyCheckbox onChange={selectUnselectItem} right={right} category={category} name="Delete" value={item.Delete} />
        //                 <MyCheckbox onChange={selectUnselectItem} right={right} category={category} name="Export" value={item.Export} />
        //             </FormGroup>}
        //         </div>
        //     </AccordionItemPanel>
        // </AccordionItem>
    );
}
