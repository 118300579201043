import React from "react";
import {

  Edit,
  FormDataConsumer,
  SimpleForm,
  TextInput,
  PasswordInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  DateInput,
  BooleanInput,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../formStyles";
export const UsersEdit = (props) => {
  const classes = useStyles({});
  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }
  return (
    <Edit title="User Edit" {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
        <TextInput source="code" validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="userName" formClassName={classes.two_three_input} />
        <PasswordInput source="password" formClassName={classes.last_three_input} />

        <ReferenceInput label="Role" source="roleTypeId" reference="role-types" formClassName={classes.one_three_input}>
          <SelectInput optionText="roleName" validate={[required()]} />
        </ReferenceInput>
        {/* <ReferenceInput label="Type" formClassName={classes.two_three_input}
          perPage={5000} source="typeId"
          filter={{ type: 'USR' }}
          reference="lookups" >
          <SelectInput optionText="name" variant="outlined" formClassName={classes.one_three_input} />
        </ReferenceInput> */}

                
        <BooleanInput source="isActive" validate={[required()]} label="Active" formClassName={classes.two_three_input} />

      </SimpleForm>
    </Edit>
  );
};
