import React from "react";
import { DisplayDate } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField, RichTextField
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput
      variant="outlined"
      label="Post Name"
      source="name~like"
      alwaysOn
    />
  </Filter>
);
export const ActivityList = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} 
    sort={{ field: 'createdDate', order: 'DESC' }}
    title="Blog Post List" filters={<Filters />}  >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' : false}>
        {propsObj.hasEdit && <EditButton />}

        <TextField source="name" label="Post Name " />
        <TextField source="category" label="Post Category" />
        <ImageField source="imageUrl" label="Post Featured Image" />
        <RichTextField source="shortText" label="Short Text " />
        <TextField source="viewCount" label="View Count" />
        <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDate source="createdDate" label="Created Date" locales="fr-FR" />
        <ReferenceField source="modifyBy" reference="users" label="Updated By" link={false}>
          <TextField source="userName" />
        </ReferenceField>
        <DisplayDate source="modifydate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
