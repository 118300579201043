import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import {ChangePassword} from "./screens/changepassword/ChangePassword";
import {MasterReport} from "./screens/reports/MasterReport";

export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,
    <Route exact path="/changepassword" render={(props) => <ChangePassword {...props}/>}  />,
    <Route exact path="/generalquery" render={(props) => <MasterReport  rptTtile="Contribution Report" rpt="GQ" {...props} />} />,
];
