import React, { useState, useEffect } from 'react';
import * as apiClient from "../../common/apiClient";
import { Create, SimpleForm, TextInput, BooleanInput, DateInput, useNotify, NumberInput, required, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment";

import "./report.css";

const useStyles = makeStyles((theme) => ({
    formControl: {
        minWidth: 150,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const MasterReport = (props: any) => {
    const notify = useNotify();
    // const classes = useStyles();
    const classes = useStyles({});
    const [cols, setCols] = useState<string[]>([]);
    const [data, setData] = useState([]);
    const [fectched, setFectched] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [startdate, setStartDate] = useState(null);
    const [enddate, setEndDate] = useState(null);
    const [roletypeId, setRoletypeId] = useState<any>("");
  

    const generateReport = () => {
        if ((startdate == undefined || startdate == null)) {

            notify("Please select Start Date", "warning");
            return;
        }
        if ((enddate == undefined || enddate == null)) {
            notify("Please select End Date", "warning");
            return;
        }

        let user: any = localStorage.getItem("userId");
        if (user) {
          user = parseInt(user);
        }
        setFectched(false);
        let sDate = moment(startdate).format('YYYY-MM-DD');
        let eDate = moment(enddate).format('YYYY-MM-DD');
        if (startdate === "") {
            sDate = "";
        }
        if (enddate === "") {
            eDate = "";
        }


        let data: any = {};
        data = {
            fromDate: sDate,
            toDate: eDate, 
            userId:user
        };

        setLoading(true);
        apiClient.loadReport("/user/report/masterreport", data).then(res => {
            console.log(res);
            setData(res);
            setFectched(true);
            if (res.length > 0) {
                let keys = Object.keys(res[0]);
                setCols(keys);
            }
            setLoading(false);

        }).catch(err => {
            console.log(err);
            setLoading(false);
        });
    }

    useEffect(() => {
        let roletype = localStorage.getItem("RoleTypeId");
        setRoletypeId(roletype)



    }, []);


    return (
        <SimpleForm toolbar={null}>
            <div className="report-heading">
                {props.rptTtile}
            </div>

            <div className="filters">
                {/* <div className="filters-input">
                    <div className="start-date">Start Date : <input type="date" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} /></div>
                    <div className="end-date"> End Date : <input type="date" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} /></div>
                </div>
                 */}
                <div className="filter-row" >
                    <div className="filter-column" >
                        <DateInput source="startDate" variant="outlined" value={startdate} onChange={(e) => { setStartDate(e.target.value); }} />
                    </div>
                    <div className="filter-column" >
                        <DateInput source="endDate" variant="outlined" value={enddate} onChange={(e) => { setEndDate(e.target.value); }} />
                    </div>
                </div>

                {fectched && data.length > 0 && <div>Total {data.length} Records found</div>}
                {fectched && data.length == 0 && <div>No Record found</div>}
                {roletypeId != "5" && <div className="filter-action">
                    {data.length > 0 && <CSVLink filename={"reportdata.csv"} data={data}>Download Report</CSVLink>}
                </div>}
            </div>
            <div className="filter-action">
                <div>
                    <Button variant="contained"
                        onClick={() => {
                            generateReport();
                        }}
                        startIcon={<AssessmentIcon />}
                        color="primary">
                        Generate Report
                    </Button>
                </div>

            </div>
            <div className="report-data">

                {isLoading && <CircularProgress />}

                <div className="w3-responsive">

                    <table className="w3-table w3-striped w3-bordered w3-table-all w3-card-4 w3-tiny">

                        <tr>
                            {cols && cols.map((item, index) => {
                                return (
                                    <th key={item}>{item}</th>
                                )
                            })}
                        </tr>

                        {data && data.map((row, rowIndex) => {
                            return (
                                <tr key={rowIndex}>
                                    {cols && cols.map((col, colIndex) => {
                                        return (
                                            <td key={rowIndex + "-" + colIndex}>
                                                {row[col]}
                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })}
                    </table>
                </div>
            </div>
        </SimpleForm>
    );
};