import React from 'react';
import moment from 'moment';


export const DisplayDate = (props) => {
    let record = props.record;
    let source = props.source;
    let dateValue = null;
    if(record)
     dateValue = record[source];
     
    if (dateValue) {
        let m = moment(dateValue);
        dateValue = m.format("DD-MM-YYYY");
    }
    return (
        <span className="date-value">{dateValue}</span>
    )
}
export const DisplayDateTime = (props) => {
    let record = props.record;
    let source = props.source;
    let dateValue = null;
    if(record)
     dateValue = record[source];

    if (dateValue) {
        let m = moment(dateValue);
        dateValue = m.format("DD-MM-YYYY hh:mm:ss");
    }
    return (
        <span className="date-value">{dateValue}</span>
    )
}