import React from 'react';
import {Create, SimpleForm,TextInput,
    DateInput,BooleanInput,NumberInput,ReferenceInput,SelectInput } from 'react-admin';

export const StateCreate = props => (
    <Create {...props} title="State">
        <SimpleForm>
            <ReferenceInput source="countryId" reference="countries">
                <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="name" />
            <TextInput source="code" />
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Create>
);