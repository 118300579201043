import * as http from "./http";


export const isDemoSite = false;

const getApi = () => {
    let hostname = window.location.host;
    var arrValue = hostname.split(".");
    var result = arrValue[0];
    if (result.includes("localhost") === true) {
        result = "http://localhost:4003/";
    }
    else {
        result = "https://api.seniorsafehhc.com/"; 
    }
    //result = "https://api.NexaIntellix-company.com/"; 
    
    return result;
};
export const APIUrl = getApi();

export const innerUploadFile = (field, data) => {
    const formData = new FormData();
    formData.append(field, data.rawFile);
    return fetch(`${APIUrl}uploadFile`, {
        method: 'POST',
        body: formData
    }).then((response) => response.json())
        .then((responseJson) => {

            return responseJson;
        })
        .catch((error) => {
            console.log(error);
        });
}

export const getBaseUrl = (url) => {
    if (url.indexOf("?") != -1) {
        url = url.substring(0, url.indexOf("?"));
    }
    return url;
}

export const UploadFile = async (field, data) => {
    if (data && data.rawFile) {
        let response = await innerUploadFile(field, data);
        if (response.files && response.files.length > 0) {
            return getBaseUrl(response.files[0].url);
        } else {
            return data;
        }
    }
    return data;
}




export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;
    return [year, month, day].join('-');
}



export const getVideoId = (content_url) => {
    var aData = content_url.split("/");
    var index = aData.length - 1;
    return aData[index];
}
export const VideoPatch = (url, token, model) => {
    let headers = new Headers();
    headers.append("Authorization", 'Bearer ' + token);
    headers.append("Content-Type", "application/json");
    return fetch(url, {
        method: "PATCH",
        body: JSON.stringify(model),
        headers
    }).then(r => {
        if (r.status == 409) {
            return r.json();
        }
        if (!r.ok) {
            throw Error(r.statusText);
        }
        return r.json();
    });
};

export const updateVideoMeta = (video_id, token, title, description) => {
    let api = "https://api.vimeo.com/videos/" + video_id;
    let data = {
        name: title,
        description: description
    };
    return VideoPatch(api, token, data).then(response => {
        return response;
    });
}
export const registerUser = (user) => {
    let api = APIUrl + "registerUser";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const approveContent = (contentId, approvalFlag) => {
    let api = APIUrl + "content/approve_disapprove_content";
    let data = {
        id: contentId,
        status: approvalFlag
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const loginUser = (user) => {
    let api = APIUrl + "user/login";
    return http.post(api, user).then(response => {
        return response;
    });
}
export const domainValidate = (domain) => {
    let api = APIUrl + "app/domain";
    return http.post(api, domain).then(response => {
        return response;
    });
}


export const StudentTracking = (content_id) => {
    let api = APIUrl + "studenttracking";
    let data = {
        content_id: content_id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const ContentReadStatus = (content_id) => {
    let api = APIUrl + "contentreadstatus";
    let data = {
        content_id: content_id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getOrgData = () => {
    let api = APIUrl + "org/1";
    return http.get(api).then(response => {
        return response;
    });
}

export const getStudentAssignment = () => {
    let api = APIUrl + "dashboard/upcoming_assignments";
    return http.get(api).then(response => {
        return response;
    });
}

export const loginUserByToken = (token) => {
    let api = APIUrl + "userById";
    let user = {
        token: token,
        StartIndex: 0,
        PageSize: 10,
    };
    return http.post(api, user).then(response => {
        return response;
    });
}

export const deleteRecord = async (path, id) => {
    let api = APIUrl + path + "/" + id;
    return await http.del(api).then(response => {
        return response;
    });
}

export const addEntiyRecord = (resource, data) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateEntiyRecord = (resource, data) => {
    let api = APIUrl + resource + "/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}

export const ChangePassword = (data) => {
    let api = APIUrl + "users/update_password";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const reviewAssignment = (data) => {
    let api = APIUrl + "assignment/review_assignment";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const getSubjectsForStudent = () => {
    let api = APIUrl + "subject/get_subjects_for_logged_user";
    return http.get(api).then(response => {
        return response;
    });
}

export const getContentPageForSubject = (subjectId) => {
    let api = APIUrl + `content/get_content_page_for_subject/${subjectId}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getAccountToken = (accountId) => {
    let api = APIUrl + `account/get_account_token`;
    let data = { "accountId": accountId };
    return http.post(api, data).then(response => {
        return response;
    });
}


export const getSectionsForClass = (classId) => {
    //let api = APIUrl + `section/${classId}`;
    var filter = { "class_id=": classId };
    const filterString = JSON.stringify(filter);
    let api = `${APIUrl}/section?filter=${filterString}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const getMeetingSchedule = (id) => {
    let api = APIUrl + `meeting_schedule/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const startMeeting = (id) => {
    let api = APIUrl + `meeting_schedule/flag_meeting_as_started`;
    let data = {
        id: id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const stopMeeting = (id) => {
    let api = APIUrl + `meeting_schedule/flag_meeting_as_stopped`;
    let data = {
        id: id
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
//Dashboard APIs
export const getAllClasses = () => {
    let api = APIUrl + `genere?pagesize=30&page=1&sortby=numeric_equivalent&sortorder=ASC&filter={}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_scholars_for_assignment = (class_id, assignmentCode, submitStatus) => {
    let api = APIUrl + `scholar/get_scholars_for_assignment`;
    let data = {
        class_id: class_id,
        assignment_id: assignmentCode,
        submit_status: submitStatus
    }
    return http.post(api, data).then(response => {
        return response;
    });
}
export const get_all_users = () => {
    let api = APIUrl + `user/get_all_users`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_all_assignments = () => {
    let api = APIUrl + `content/get_all_assignments`;
    let data = {

    }
    return http.post(api, data).then(response => {
        return response;
    });
}

export const get_all_classes_for_employee = () => {
    let api = APIUrl + `genere/get_all_classes_for_employee`;
    return http.get(api).then(response => {
        return response;
    });
}
export const get_dashboard = (userid) => {
    let api = APIUrl + `users/dashboard/` + userid;
    return http.get(api).then(response => {
        return response;
    });
}
export const GetDashboardHoliday = () => {
    let api = `${APIUrl}employees/dashboardholiday`;
    return http.get(api).then(response => {
        return response;
    });
}

export const get_attendancestatus = (userid) => {
    let api = APIUrl + `employees/checkemploginstatus/` + userid;
    return http.get(api).then(response => {
        return response;
    });
}

export const get_all_assignments_for_class_from_faculty = (class_id) => {
    let api = APIUrl + `content/get_all_assignments_for_class_from_faculty`;
    let data = {
        class_id: class_id
    };
    return http.post(api, data).then(response => {
        return response;
    });
}

// export const updateRolerights = (data) => {
//     let api = APIUrl + "/role-types/rights";
//     return http.post(api, data).then(response => {
//         return response;
//     });
// }
export const updateUserAccountMapping = (data) => {
    let api = APIUrl + "/useraccountmaps/mapaccount";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateUserRoleMapping = (data) => {
    let api = APIUrl + "/userrolemap/maprole";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateUserDeparmentMapping = (data) => {
    let api = APIUrl + "/userdepartmentmaps/map";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateUserfDeparmentMapping = (data) => {
    let api = APIUrl + "/userfullfillmentdeptmaps/map";
    return http.post(api, data).then(response => {
        return response;
    });
}



export const updateaccountprice = (data) => {
    let api = APIUrl + "/accountlabpartnertestprices/mapprice";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const mapglobalRouting = (data) => {
    let api = APIUrl + "/globalroutings/map";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const mapTestRouting = (data) => {
    let api = APIUrl + "/testroutings/map";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const MapGroupTest = (data) => {
    let api = APIUrl + "/testgroupmaps/maptest";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const fetchGridData = (resource: any, filters: any) => {
    let api = APIUrl + "/" + resource + filters;
    console.log(api);
    return http.get(api).then(response => {

        return response;
    })
}
export const getPatientData = (id) => {
    let api = APIUrl + "/patients/" + id;
    return http.get(api).then(response => {
        return response;
    })
}

export const getEmployeeData = (id) => {
    let api = APIUrl + "/employees/" + id;
    return http.get(api).then(response => {
        return response;
    })
}

export const getInsurerData = (id) => {
    let api = APIUrl + "/insurers/" + id;
    return http.get(api).then(response => {
        return response;
    })
}

export const getHospitalData = (id) => {
    let api = APIUrl + "/hospitals/" + id;
    return http.get(api).then(response => {
        return response;
    })
}


export const addFromSetup = (id, config) => {
    let api = APIUrl + "/realiz-ords/addfromsetup/" + id + "/" + config;
    return http.get(api).then(response => {
        return response;
    })
}
export const exportToCSV = (resource: any, filters: any) => {
    let api = APIUrl + "/" + resource + filters;
    return http.get(api).then(response => {

        return response;
    })
}
export const managePatient = (data) => {
    let api = APIUrl + "/patient/manage";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getBookigList = (data) => {
    let api = APIUrl + "/testbookingorders/list";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const SearchTest = (data) => {
    let api = APIUrl + "/testaccountmap/find";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const addEditBooking = (data) => {
    let api = APIUrl + "/testbookingorders/addedit";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const DeleteBookedTest = (data) => {
    let api = APIUrl + "/testbookingorders/delete";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const GetBookingData = (data) => {
    let api = APIUrl + "/testbookingorders/bookingdata";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const updateRealizationDocument = (data) => {
    let api = APIUrl + "/realiz-ord-dets/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const updateHospitalDocument = (data) => {
    let api = APIUrl + "/hospital-docs/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}

export const createHospitalTpaConfig = (data) => {
    let api = APIUrl + "hospital-dets";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createHospitalInsurerConfig = (data) => {
    let api = APIUrl + "hospital-ins-dets";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const createHospitalRealisation = (data) => {
    let api = APIUrl + "hospital-real-dets";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const loadReport = (resource, data) => {
    let api = APIUrl + resource;
    return http.post(api, data).then(response => {
        return response;
    });
}
export const getdata = (resource) => {
    let api = `${APIUrl}${resource}?filter[offset]=0&filter[limit]=250&filter[order]=id%20DESC`;
    return http.get(api).then(response => {
        return response;
    });
}
export const getdataWithOrder = (resource, orderField, sortDirection) => {
    let api = `${APIUrl}${resource}?filter[offset]=0&filter[limit]=25000&filter[order]=${orderField}%20${sortDirection}`;
    return http.get(api).then(response => {
        return response;
    });
}


export const updateRolerights = (data) => {
    let api = APIUrl + "role-types/rights";
    return http.post(api, data).then(response => {
        return response;
    });
}
export const updateRoleType = (id, data) => {
    let api = APIUrl + `role-types/${id}`;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const getmodules = (user_id) => {
    let api = APIUrl + "employees/getmodules/" + user_id;
    return http.get(api).then(response => {
        return response;
    });
}

export const getfunctionbyroletype = (roleid) => {
    let api = `${APIUrl}employees/getfunctionbyroletype/${roleid}`;
    return http.get(api).then(response => {
        return response;
    });
}


export const getGetHospitalDataForPrint = (id) => {
    let api = `${APIUrl}hospitals/printdata/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const realizationOrderPrint = (id) => {
    let api = `${APIUrl}realiz-ords/printdata/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}
export const realizationOrderPrintTPADeclaration = (id, tpaId) => {
    let api = `${APIUrl}realiz-ords/printdatatpadeclaration/${tpaId}/${id}`;
    return http.get(api).then(response => {
        return response;
    });
}

export const addCorporate = (data) => {
    let api = APIUrl + "corporates";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const addHospital = (data) => {
    let api = APIUrl + "hospitals";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const deleteRealizationDocument = (id) => {
    let api = APIUrl + "/realiz-ord-dets/" + id;
    return http.del(api).then(response => {
        return response;
    });
}

export const gethospitaldata = (hospitalId) => {
    let api = APIUrl + "hospital-invoice/hospitaldata/" + hospitalId;
    return http.get(api).then(response => {
        return response;
    });
}

export const get_tpa_insurer_data = (toId, istpa) => {
    let api = APIUrl + "claim-invs/to_data/" + toId + "/" + istpa;
    return http.get(api).then(response => {
        return response;
    });
}


export const getRealizord_BillingPerc_ByHospDateRange = (hospitalId, fromdate, todate, invType) => {
    let api = APIUrl + `hospital-invoice/Realizord_BillingPerc_ByHospDateRange/${hospitalId}/${fromdate}/${todate}/${invType}`
    return http.get(api).then(response => {
        return response;
    });
}

export const getCalimOrd_BillingPerc_ByDateRange = (toid, totypeid, fromdate, todate) => {
    let api = APIUrl + `claim-invs/CalimOrd_BillingPerc_ByDateRange/${toid}/${totypeid}/${fromdate}/${todate}`
    return http.get(api).then(response => {
        return response;
    });
}




export const getsalary = (id, month, year) => {
    let api = APIUrl + `employee-sal/getsalary/${id}/${month}/${year}`
    return http.get(api).then(response => {
        return response;
    });
}



export const getInvoiceData = (invoiceid) => {
    let api = APIUrl + `/claim-invs/getinvoicedata/${invoiceid}`
    return http.get(api).then(response => {
        return response;
    });
}

export const getInvoiceDataForPrint = (invoiceid) => {
    let api = APIUrl + `/hospital-invoice/printinvoice/${invoiceid}`
    return http.get(api).then(response => {
        return response;
    });
}
export const getRepoClaimInvstPrint = (invoiceid) => {
    let api = APIUrl + `/claim-ords/printRepoClaimInvstPrint/${invoiceid}`
    return http.get(api).then(response => {
        return response;
    });
}


export const getpayslipPrint = (id) => {
    let api = APIUrl + `/employee-sal/printpayslip/${id}`
    return http.get(api).then(response => {
        return response;
    });
}


export const addupdateHospitalInvoice = (data) => {
    let api = APIUrl + "hospital-invoice/createupdateinvoice";
    return http.post(api, data).then(response => {
        return response;
    });
}

export const addupdateClaimInvoice = (data) => {
    let api = APIUrl + "claim-invs/createupdateinvoice";
    return http.post(api, data).then(response => {
        return response;
    });
}


// export const addupdateClaimInvoice = (data) => {
//     let api = APIUrl + "hospital-invoice/createupdateinvoice";
//     return http.post(api, data).then(response => {
//         return response;
//     });
// }
export const createupdatesalary = (data) => {
    let api = APIUrl + "employee-sal/createupdatesalary";
    return http.post(api, data).then(response => {
        return response;
    });
}



export const updateleave = (data) => {
    let api = APIUrl + "/employee-leaves/" + data.id;
    return http.put(api, data).then(response => {
        return response;
    });
}
export const updateAttendance = (data) => {
    let api = APIUrl + "/employees/attendanceentry";
    return http.post(api, data).then(response => {
        return response;
    });
}


